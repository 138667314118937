html, body {
  height: 100%;
  margin: 0;
}

div#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
